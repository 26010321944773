import "./window-variables";

import { id } from "@discovery/prelude/lib/data/function";
import { installPolyfills } from "./polyfills/install-polyfills";
import NewRelicBrowser from "new-relic-browser";

declare global {
  interface Window {
    isNewRelicEnabled: boolean;
    newrelic: typeof NewRelicBrowser;
    _translations: { default: Record<string, string> };
    EDRSurvey: {
      redeploy: () => void;
    };
  }
}
window.isNewRelicEnabled = true;
/**
 * Note: Unless explicitly imported somewhere, the Nix build will break when resolving React dependencies.
 * However if we explcitly import React somewhere in the components library, the build will resolve the
 * dependency using this import.
 */
// @TODO 1app - investigate 👆, see if we need to explicitly import React here or elsewhere

/* The player expects this to exist*/
// TODO: check if we can remove this
window.__ = id;

import "@discovery/common-dplus/lib/base.css";
import "../assets/index.css";

const main = () => import("./start-app").then((x) => x.main());

installPolyfills().then(() => main());

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .getRegistrations()
    .then((registrations) =>
      registrations.forEach((registration) => registration.unregister())
    );
}
