const polyfills: Promise<unknown>[] = [];

if (typeof fetch !== "function") {
  polyfills.push(
    // prettier-ignore
    import(/* webpackChunkName: "polyfill-fetch" */ "isomorphic-fetch")
  );
}

type PolyfillArray = [] & {
  flat: () => void;
};
if (typeof ([] as PolyfillArray).flat !== "function") {
  polyfills.push(
    // TODO remove when core-js publish types for version 3
    // prettier-ignore
    import(/* webpackChunkName: "polyfill-flat" */ "core-js/features/array/flat")
  );
}

if (
  !("IntersectionObserver" in window) ||
  !("IntersectionObserverEntry" in window) ||
  !("intersectionRatio" in window.IntersectionObserverEntry?.prototype)
) {
  polyfills.push(
    // prettier-ignore
    import(/* webpackChunkName: "polyfill-intersectionObserver" */ "intersection-observer")
  );
}

if (
  typeof Object.values !== "function" ||
  typeof Object.fromEntries !== "function"
) {
  polyfills.push(
    // TODO remove when core-js publish types for version 3
    // prettier-ignore
    import(/* webpackChunkName: "polyfill-object" */ "core-js/es/object")
  );
}

const installPolyfills = () => Promise.all(polyfills);
export { installPolyfills };
