type windowVariables = Window & {
  __meta: {
    VERSION?: string;
    BUILD_MODE?: string;
  };
};

(window as unknown as windowVariables).__meta = {
  VERSION: process.env.LAST_COMMIT,
  BUILD_MODE: process.env.BUILD_MODE,
};
